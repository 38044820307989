import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { getThemeStyles } from 'holded/lib/styles';
import { CardList2Section } from 'holded/modules/cms/domain/components/card';
import { CardListTheme, defaultThemeValues } from 'holded/modules/cms/ui/sections/cardList/cardListTheme';

const Card = dynamic(() => import('holded/modules/cms/ui/shared/components/Card'));

const CardList2 = ({ title, cards, description, background }: CardList2Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  useEffect(() => {
    const themeStyles = getThemeStyles(CardListTheme.cardList, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={`${themeValues.background} flex`}>
      <div className={`lg:grid lg:grid-cols-3 md:flex-row mx-auto py-10 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-10`}>
        <div className={'md:pr-20 pb-12'}>
          <h2 className={`${themeValues.titleColor} text-h4 leading-8 md:text-h3 font-extrabold mb-3 md:leading-10`}>
            {title}
          </h2>
          <p className={`${themeValues.descriptionColor} font-normal text-b1 leading-7`}>{description}</p>
        </div>
        <div className="space-y-12 col-span-2">
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-8"
          >
            {cards?.map((card) => (
              <li key={card.id} className="pb-10 lg:pb-0">
                <Card background={background} key={card.id} data={card} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardList2;
